/*-------------------------------------------
  Shape styles
-------------------------------------------*/
.shapes {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;

  &:after {
    @extend %cover;
    background-image: linear-gradient(0deg, $white 33.33%, transparent 33.33%, transparent 50%, $white 50%, $white 83.33%, transparent 83.33%, transparent 100%);
    background-size: 12px 12px;
    content: '';

    // Dark mode
    @media (prefers-color-scheme: dark) {
      background-image: linear-gradient(0deg, $black 33.33%, transparent 33.33%, transparent 50%, $black 50%, $black 83.33%, transparent 83.33%, transparent 100%);
    }
  }
}

.shapes__canvas {
  @extend %cover;
}

.shape {
  transform-origin: center;
}

// Shape rotation and coloring
$fills: $accent-1, $accent-2, $accent-3, $accent-4, $accent-5;

@for $i from 1 to 6 {
  .shape:nth-child(#{$i}) {
    fill: #{nth($fills, $i)};
  }
}