/*-------------------------------------------
  Global styles
-------------------------------------------*/
html {
  font-size: 16px;
}

$strokeSize: 1px;

body {
  @extend %antialias;
  background: $white;
  color: $text;
  font-family: $sans-serif;
  @extend %sm;
  text-shadow: $strokeSize 0 0 $white,
               -$strokeSize 0 0 $white,
               0 $strokeSize 0 $white,
               0 -$strokeSize 0 $white;

               // Dark mode
  @media (prefers-color-scheme: dark) {
    background: $black;
    color: $white;
    text-shadow: $strokeSize 0 0 $black,
               -$strokeSize 0 0 $black,
               0 $strokeSize 0 $black,
               0 -$strokeSize 0 $black;
  }
}

// Container elements
.content {
  position: relative;
}

.wrap, {
  margin: 0 auto;
  max-width: $page-width;
  padding: 0 $page-padding;

  @include min-up($tablet) {
    padding: 0 $page-padding-lg;
  }
}

// Links
a, %link {
  @extend %transition;
  color: $accent-1;
  text-decoration: underline;

  .icon {
    @extend %transition;
  }

  &:hover {
    color: darken($accent-1, 20);
    text-decoration: underline;

    .icon {
      fill: darken($accent-1, 20);
    }
  }

  &:focus {
    &:not([class*='btn']):not(.nav__item):not(.tab) {
      color: darken($accent-1, 20);
      text-decoration: underline;
    }
  }
}

// No mobile
.no-mobile {
  display: none;

  @include min-up($tablet) {
    display: block;
  }
}