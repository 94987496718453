/*-------------------------------------------
  Work styles
-------------------------------------------*/
$colors: $accent-1, $accent-2, $accent-3, $accent-4, $accent-5;

.work {
  overflow: hidden;
  margin: -5px;
  opacity: 0;
  padding: 5px;
  position: relative;
  text-align: center;
  transform: translateY(20px);
  @extend %transition;

  // Backdrop
  &:before,
  &:after {
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &:after {
    background-image: linear-gradient(0deg, $white 33.33%, transparent 33.33%, transparent 50%, $white 50%, $white 83.33%, transparent 83.33%, transparent 100%);
    background-size: 12px 12px;

    @media (prefers-color-scheme: dark) {
      background-image: linear-gradient(0deg, $black 33.33%, transparent 33.33%, transparent 50%, $black 50%, $black 83.33%, transparent 83.33%, transparent 100%);
    }
  }

  @for $i from 1 to 6 {
    li:nth-child(#{$i}) &,
    li:nth-child(5n + #{$i}) & {
      &:before {
        background: #{nth($colors, $i)};
      }
    }
  }
}

.work--visible {
  opacity: 1;
  transform: none;
}

.work__head {
  background: $black;
  bottom: 5px;
  color: $white;
  left: 5px;
  opacity: 0;
  padding: 2px 5px;
  pointer-events: none;
  position: absolute;
  right: 5px;
  text-shadow: none;
  top: 5px;
  transform: scale(.5);
  @extend %transition;

  .wrap {
    @extend %center-both;
    position: relative;
  }

  .work:hover & {
    opacity: 1;
    transform: none;
  }

  @for $i from 1 to 6 {
    li:nth-child(#{$i}) &,
    li:nth-child(5n + #{$i}) & {
      background: #{nth($colors, $i)};
    }
  }

  li:nth-child(5) & {
    color: $text;
  }
}

.work__title {
  @extend %h4;
  font-weight: 700;
}

.work__details {
  display: block;
  line-height: 2;
}

.work__link {
  @extend %cover;
  overflow: hidden;
  text-indent: -999em;
}
