/*-------------------------------------------
  Grid styles
-------------------------------------------*/
.grid {
  @extend %list-reset;
  margin: 0 auto;
  max-width: rem(830);
  text-align: center;

  @include min-up($tablet) {
    display: flex;
    flex-flow: row wrap;
    text-align: left;
  }
}

.grid__item {
  display: inline-block;
  flex-basis: 100%;
  margin-bottom: $page-padding;
  position: relative;
  z-index: 1;

  @include min-up($tablet) {
    flex-basis: calc(50% - #{$page-padding});
    margin-bottom: $page-padding-lg;

    &:nth-child(odd) {
      margin-right: $page-padding;
    }

    &:nth-child(even) {
      margin-left: $page-padding;
      transform: translateY(-100px);
    }
  }
}

.grid__image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}