/*-------------------------------------------
  Helper classes
-------------------------------------------*/
// List resets
%list-reset {
  list-style: none;
  margin: 0;
}

%inline-list {
  @extend %list-reset;

  > li {
    display: inline-block;
  }
}

// Button resets
%btn-reset {
  background: none;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}

// Centering
%center-horz {
  left: 50%;
  transform: translateX(-50%);
}

%center-vert {
  top: 50%;
  transform: translateY(-50%);
}

%center-both {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

%center-vert-alt {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -10px;
    vertical-align: middle;
    width: 10px;
  }
}

// Cover
%cover {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// Transitions
%transition {
  transition: all .25s ease;
}

// Meta text
.meta, %meta {
	border: 0!important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px!important;
	overflow: hidden!important;
	padding: 0!important;
	position: absolute!important;
	width: 1px!important;
}

%unmeta {
  clip: auto;
  height: auto!important;
  overflow: visible!important;
  position: static!important;
  width: auto!important;
}

// Font smoothing
%antialias {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%subpixel {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

%auto-antialias {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

// Dropdown arrow
%down-arrow {
  &:after {
    border: 4px solid $text;
    border-bottom-width: 0;
    border-right-color: transparent;
    border-left-color: transparent;
    content: '';
    display: inline-block;
    height: 0;
    margin-left: 4px;
    vertical-align: middle;
    width: 0;
  }
}

// Double space
%double-space {
  &:after {
    background: $black;
    box-shadow: 1.25ex 0 0 0 $black;
    content: '';
    display: inline-block;
    height: .3ex;
    width: 1ex;
  }
}

%double-space-before {
  &:before {
    background: $black;
    box-shadow: 1.25ex 0 0 0 $black;
    content: '';
    display: inline-block;
    height: .3ex;
    width: 1ex;
  }
}