/*-------------------------------------------
  Brand styles
-------------------------------------------*/
$logo-width: rem(73);

.brand {
  display: block;
}

.brand__mark {
  background: $black;
  mask-image: url(../img/logo.svg);
  mask-repeat: no-repeat;
  mask-size: contain;
  height: rem(33);
  overflow: hidden;
  position: relative;
  text-indent: -999em;
  width: $logo-width;

  @media (prefers-color-scheme: dark) {
    background: $white;
  }

  &:before {
    background: $accent-2;
    border-left: $logo-width solid $accent-1;
    border-right: $logo-width solid $accent-4;
    box-shadow: inset (-$logo-width) 0 0 0 $accent-5,
                inset (-$logo-width * 2) 0 0 0 $accent-3;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: all 1s cubic-bezier(0.895, 0.030, 0.685, 0.220);
    width: $logo-width * 5;
  }

  &:hover {
    &:before {
      transform: none;
      transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
  }
}