/*-------------------------------------------
  Banner styles
-------------------------------------------*/
.banner {
  align-items: flex-end;
  display: flex;

  @include min-up($tablet) {
    height: rem(400);
    padding-bottom: 50px;
  }

  .wrap {
    flex-basis: 100%;
  }
}

.banner__title {
  @extend %xl;
  font-weight: 700;
  line-height: 1.2;
  text-transform: lowercase;
}