/*-------------------------------------------
  Page styles
-------------------------------------------*/
.page {
  background: $tint;
  padding: $page-padding;

  h1, h2, h3 {
    font-weight: 700;
  }

  @include min-up($tablet) {
    padding: $page-padding-lg;
  }
  
  @media (prefers-color-scheme: dark) {
    background: $tint-dark;
  }
}

// Intro
.page__intro {
  @extend %double-space-before;
  margin-bottom: rem(40);
  max-width: rem(625);

  &:before {
    @extend %h2;
    margin-bottom: rem(10);
  }

  p {
    @extend %h5;
  }
}

// Sections
.page__section {
  border-top: 2px solid $border-color;
  padding: rem(40) 0;
  position: relative;

  // Dark mode
  @media (prefers-color-scheme: dark) {
    border-top-color: $border-color-dark;
  }

  // &:before {
  //   background: url(../img/border.svg) center repeat-x;
  //   content: '';
  //   height: rem(12);
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  // }

  .media__subject,
  .media__copy {
    flex-basis: 50%;
    flex-shrink: 1;
    margin: 0;
  }

  .media__copy {
    &:only-child {
      flex-basis: 100%;
      margin: 0 auto;
      max-width: rem(520);
    }
  }
}