/*-------------------------------------------
  Header styles
-------------------------------------------*/
.site-head {
  padding: 15px 0;

  .wrap {
    align-content: center;
    background: $tint;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 10px;

    @media (prefers-color-scheme: dark) {
      background: $tint-dark;
    }
  }
}