/*-------------------------------------------
  Colors
-------------------------------------------*/
// Brand colors
$blue: #15a2f8;
$green: #20a841;
$purple: #a13eff;
$red: #ea3222;
$yellow: #ffd600;

// Grays
$black: #333645;
$white: #fffdf6;

// Accents
$accent-1: $blue;
$accent-2: $purple;
$accent-3: $green;
$accent-4: $red;
$accent-5: $yellow;

// UI colors
$text: $black;
$tint: transparentize($white, .15);
$tint-dark: transparentize($black, .15);
$border-color: transparentize($black, .9);
$border-color-dark: transparentize($white, .9);

/*-------------------------------------------
  Fonts
-------------------------------------------*/
$sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;

/*-------------------------------------------
  Stacking
-------------------------------------------*/
$bottom: 1000;
$middle: 2000;
$top: 3000;

/*-------------------------------------------
  Breakpoints
-------------------------------------------*/
// Min-up
$mobile: em(320);
$mobile-lg: em(460);
$tablet: em(768);
$desktop-sm: em(820);
$desktop: em(1024);

// Max-down
$mobile-down: em(319);
$mobile-lg-down: em(459);
$tablet-down: em(767);
$desktop-down: em(1023);

/*-------------------------------------------
  Grid settings
-------------------------------------------*/
$page-width: rem(1120);
$page-padding: 15px;
$page-padding-lg: 30px;

/*-------------------------------------------
  Misc
-------------------------------------------*/

// Border radius
$border-radius-amount: 3px;
$border-radius-all: $border-radius-amount;
$border-radius-top: $border-radius-amount $border-radius-amount 0 0;
$border-radius-bottom: 0 0 $border-radius-amount $border-radius-amount;
$border-radius-left: $border-radius-amount 0 0 $border-radius-amount;
$border-radius-right: 0 $border-radius-amount $border-radius-amount 0;

/*-------------------------------------------
  Text inputs
-------------------------------------------*/
$text-input-list: (
  'input[type="color"]',
  'input[type="date"]',
  'input[type="datetime-local"]',
  'input[type="datetime"]',
  'input[type="email"]',
  'input[type="month"]',
  'input[type="number"]',
  'input[type="password"]',
  'input[type="search"]',
  'input[type="tel"]',
  'input[type="text"]',
  'input[type="time"]',
  'input[type="url"]',
  'input[type="week"]',
);

$all-text-inputs: ();

@each $input in $text-input-list {
  $all-text-inputs: append($all-text-inputs, $input, comma);
}