/*-------------------------------------------
  Media styles
-------------------------------------------*/
.media {
  @include min-up($tablet) {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }
}

.media__subject {
  flex-shrink: 0;
  margin-right: $page-padding;
  order: 0;
  text-align: center;

  @include min-up($tablet) {
    margin-right: $page-padding-lg;
  }
}

.media__copy {
  .media:nth-child(even) & {
    order: 1;
  }
}