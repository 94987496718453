/*-------------------------------------------
  Navigation styles
-------------------------------------------*/
.nav {
  display: none;
  margin-bottom: rem(-2);

  @include min-up($tablet) {
    display: block;
  }
}

.nav__list {
  @extend %inline-list;

  > li + li {
    margin-left: 30px;
  }
}

.nav__item {
  color: $black;
  display: inline-block;
  font-weight: 700;
  overflow: hidden;
  padding: 2px 5px;
  position: relative;
  text-decoration: none;
  text-shadow: none;
  z-index: 1;

  @media (prefers-color-scheme: dark) {
    color: $white;
  }

  &:before,
  &:after {
    @extend %cover;
    content: '';
    transition: width .25s ease;
    width: 0;
    z-index: -1;
  }

  &:before {
    background: $accent-1;
    left: auto;
    right: 0;
  }

  &:after {
    border-bottom: 2px solid $accent-4;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    transition-delay: .25s;

    &:before,
    &:after {
      width: 100%;
    }

    &:before {
      left: 0;
      transition-delay: .25s;
      right: auto;
    }

    &:after {
      left: auto;
      right: 0;
      transition-delay: 0s;
    }
  }
}